import React from "react"
import { Container, Row, Col, Fade } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import zIndex from "@material-ui/core/styles/zIndex"

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    
      <Layout>
        <Container fluid>
          <SEO title="Home" />
          <div id="top">
            <Row >
              <div className="col-12 col-lg-6 p-0  h-md-100" id="immobilien">
                <div className="text-white d-md-flex align-items-center h-100 p-0 p-md-5 text-center justify-content-center">
                  <div className="logoarea pt-5 pb-5"></div>
                </div>
              </div>

              <div className="col-12 col-lg-6 p-0 bg-white h-md-100 loginarea" >
                <div className="d-md-flex align-items-center h-md-100 p-0 p-md-5 justify-content-center">
                  <div className="col-md-8 col-12 text-center">
                    <Img
                      fixed={data.logoImmobilien.childImageSharp.fixed}
                      style={{ height: "200px" }}
                    />
                    <h2>
                      Kauf oder Verkauf landwirtschaftlicher Immobilien.
                      <br /> Betriebe, Ackerland, Forstflächen, Hofstellen …
                    </h2>
                    <p>
                      Sehen Sie sich bei Angeboten und Gesuchen um, nutzen Sie
                      unser Kaufformular. <br />
                      Wir unterstützen Sie beim Kauf und Verkauf.
                    </p>
                    <a
                      href="/angebote"
                      className="btn btn-success text-white m-2"
                    >
                      Immobilienangebote
                    </a>{" "}
                    <a
                      href="/immobilien-suche"
                      className="btn btn-success text-white m-2"
                    >
                      Kaufformular
                    </a>
                  </div>
                </div>
              </div>
            </Row>

            <Row >
              <div className="col-12 col-lg-6 p-0 bg-blue h-md-100 order-last order-lg-first">
                <div className="text-white d-md-flex align-items-center h-100 p-0 p-md-5 text-center justify-content-center">
                  <div className="col-md-8 col-md-offset-2 col-12 text-center">
                    <Img
                      fixed={data.logoWohnungsbau.childImageSharp.fixed}
                      style={{ height: "200px" }}
                    />{" "}
                    <h2 className="text-white h2-responsive">Wohnungsbau - Projektentwicklung</h2>
                    <p>
                      Geschosswohnungsbau in innerstädtischen Lagen,
                      Baulanderschließung.
                      <br /> An Grundstücksangeboten sind wir interessiert!
                    </p>
                    <a href="/kontakt-form" className="btn btn-outline-light m-2">
                      Kontaktformular
                    </a>
                    <a href="/wohnungsbau" className="btn btn-outline-light m-2">
                      Mehr...
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 p-0 h-md-100 loginarea"  id="wohnungsbau">
                <div className="d-md-flex align-items-center h-md-100 p-0 p-md-5 justify-content-center"></div>
              </div>
            </Row>

            <Row>
              <div className="col-12 col-lg-6 p-0  h-md-100"  id="hausverwaltung">
                <div className="text-white d-md-flex align-items-center h-100 p-0 p-md-5 text-center justify-content-center">
                  <div className="logoarea pt-5 pb-5"></div>
                </div>
              </div>

              <div className="col-12 col-lg-6 p-0 bg-white h-md-100 loginarea">
                <div className="d-md-flex align-items-center h-md-100 p-0 p-md-5 justify-content-center">
                  <div className="col-md-8 col-12 text-center">
                    <Img
                      fixed={data.logoHausverwaltung.childImageSharp.fixed}
                      style={{ height: "200px" }}
                    />{" "}
                    <h2>Hausverwaltung</h2>
                    <p>Management von Miet- und Eigentumswohnungen.</p>
                    <a
                      href="/kontakt-form"
                      className="btn btn-success text-white m-2"
                    >
                      Kontaktformular
                    </a>
                    <a
                      href="/vermietung"
                      className="btn btn-success text-white m-2"
                    >
                      Mehr...
                    </a>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
       
      </Layout>
    
  )
}

export default IndexPage
export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(height: 200) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
export const query = graphql`
  query {
    logoImmobilien: file(relativePath: { eq: "logo_immo.png" }) {
      ...squareImage
    }
    logoWohnungsbau: file(relativePath: { eq: "logo_wohnungsbau.png" }) {
      ...squareImage
    }
    logoHausverwaltung: file(relativePath: { eq: "logo_verwaltung.png" }) {
      ...squareImage
    }
  }
`
